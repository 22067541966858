import React from 'react';
import Image from 'next/image';
import usePrefix from '@/hooks/usePrefix';
import { CDN2 } from '@/helpers/api';
import { pushLayerEvent } from '@/utils/gtag';

export interface IFeatures {
  width?: number;
  height?: number;
}

export default function ButtonStore({ width, height }: IFeatures) {
  const handleIos = () => {
    pushLayerEvent('nosonOpenIosStore', {});
  };
  const handleAndroid = () => {
    pushLayerEvent('nosonOpenAndroidStore', {});
  };

  const prefix = usePrefix();
  return (
    <>
      <a
        href="https://apps.apple.com/us/app/onairparking/id6444409018"
        onClick={handleIos}
        target="_blank"
        rel="noopener noreferrer"
        className="table"
      >
        <Image
          src={CDN2() + '/staticmyapp/aple-pay.svg'}
          className="rounded-lg"
          width={width}
          height={height}
          alt="Download our app - Apple"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=io.noson.onairparking&pli=1"
        onClick={handleAndroid}
        target="_blank"
        rel="noopener noreferrer"
        className="table"
      >
        <Image
          src={CDN2() + '/staticmyapp/play-store.svg'}
          className="rounded-lg"
          width={width}
          height={height}
          alt="Download our app - Android"
        />
      </a>
    </>
  );
}
